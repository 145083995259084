
import Vue from "vue";
import FilterCategoryWord from "@/components/Home/FilterCategoryWord.vue";
import AssemblyTable from "@/components/Home/AssemblyTable.vue";
import ShoppingCart from "@/components/ShoppingCart/ShoppingCart.vue";
import OrderHistory from "@/components/OrderHistory/OrderHistory.vue";
import AppBar from "@/components/Global/AppBar.vue";
import GBanner from "@/components/Global/GBanner.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: gettersDb } = createNamespacedHelpers("realtimedb");
export default Vue.extend({
  name: "HomeView",
  data: () => ({
    idel: 0,
  }),
  computed: {
    mode() {
      return this.$store.state.mode;
    },
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    profile() {
      return this.$store.getters["profile"];
    },
    bgcolor() {
      if (this.mode !== "home") {
        return "blue-grey lighten-5";
      }
      return "";
    },
    ...gettersDb(["isBusinessHours"]),
  },
  mounted() {
    this.idel = setInterval(() => {
      if (!this.isBusinessHours) {
        this.$router.push("/message");
        clearInterval(this.idel);
      }
      if (!this.profile) {
        this.$router.push("/message?undefinedShop=true");
        clearInterval(this.idel);
      }
    }, 10000);
  },
  components: {
    FilterCategoryWord,
    AssemblyTable,
    ShoppingCart,
    AppBar,
    GBanner,
    OrderHistory,
  },
});
