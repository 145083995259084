/* eslint-disable @typescript-eslint/no-explicit-any */
import { shopRankCodeToName } from "@/config/const";
import { Assembly, Order, Shop } from "@/types/types";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

export const unparse = (newJson: any) => {
  const worksheet = XLSX.utils.json_to_sheet(newJson);
  return worksheet;
};
export const parse = (orders: Order[], shops: Shop[], assemblies: Assembly[]) => {
  return orders
    .map((d) =>
      d.details
        .flatMap((detail) => ({
          order_id: d.id,
          ncode: d.ncode,
          created: dayjs(d.created).format("YYYY-MM-DD"),
          createdTime: dayjs(d.created).format("HH:mm:ss"),
          ...detail,
          productName: detail.name,
          ...(shops.find((x) => x.ncode === d.ncode) ?? {}),
        }))
        .map((d) => ({
          注文日: d.created,
          注文時間: d.createdTime,
          区分: shopRankCodeToName[d?.wholesaleRank ?? "8"] ?? "",
          販売店コード: d?.shopcode,
          店名: d.name,
          郵便番号: d?.zip,
          住所: d?.address ?? "",
          電話番号: d?.tel,
          部品番号: d.id,
          部品名: d.productName,
          数量: d.quantity,
          備考: d?.note,
          BO: d.bo || 0,
          ロケーション: assemblies.find((assembly) => assembly.id === d.id)?.locationCode,
          order_id: d.order_id,
        }))
    )
    .flat();
};
export const parseByShop = (orders: Order[], shops: Shop[], assemblies: Assembly[]) => {
  const distinctNcode = Array.from(new Set(orders.map((d) => d.ncode)));
  const afterPerspective = {} as { [key: string]: any };
  distinctNcode.forEach((ncode) => {
    afterPerspective[ncode] = orders
      .filter((d) => d.ncode === ncode)
      .map((d) =>
        d.details
          .flatMap((detail) => ({
            order_id: d.id,
            created: dayjs(d.created).format("YYYY-MM-DD"),
            createdTime: dayjs(d.created).format("HH:mm:ss"),
            ...detail,
            productName: detail.name,
            ...(shops.find((x) => x.ncode === d.ncode) ?? {
              ncode: d.ncode,
              name: "",
              shopcode: "",
              zip: "",
              address: "",
              tel: "",
              wholesaleRank: "",
            }),
          }))
          .map((d) => ({
            注文日: d.created,
            注文時間: d.createdTime,
            区分: shopRankCodeToName[d?.wholesaleRank ?? "8"] ?? "",
            販売店コード: d?.shopcode,
            店名: d.name,
            郵便番号: d?.zip,
            住所: d?.address ?? "",
            電話番号: d?.tel,
            部品番号: d.id,
            部品名: d.productName,
            数量: d.quantity,
            備考: d?.note,
            BO: d.bo || 0,
            ロケーション: assemblies.find((assembly) => assembly.id === d.id)?.locationCode,
            order_id: d.order_id,
          }))
      )
      .flat();
  });
  console.log(
    afterPerspective,
    shops.filter(({ ncode }) => distinctNcode.includes(ncode))
  );
  return {
    parseJson: afterPerspective,
    shops: shops.filter(({ ncode }) => distinctNcode.includes(ncode)),
  };
};
export const download = (newJson: any, filename: string, sheetname?: string) => {
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, unparse(newJson), sheetname ?? filename);
  XLSX.writeFileXLSX(workbook, `${filename}.xlsx`);
};
export const downloadByShop = (newJson: any, shops: Shop[], filename: string) => {
  const workbook = XLSX.utils.book_new();
  shops.forEach(({ ncode, name }) => {
    console.log(ncode, name, name.trim() === "");
    XLSX.utils.book_append_sheet(workbook, unparse(newJson[ncode]), name.trim() === "" ? ncode : name);
  });
  XLSX.writeFileXLSX(workbook, `${filename}.xlsx`);
};
