
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const {
  mapState: stateBanner,
  mapGetters: gettersBanner,
  mapActions: actionsBanner,
} = createNamespacedHelpers("banner");
export default Vue.extend({
  name: "GBanner",
  data: () => ({
    idle: undefined as number | undefined,
  }),
  computed: {
    ...stateBanner(["mode"]),
    ...gettersBanner(["show"]),
  },
  watch: {
    show(newVal) {
      if (newVal) {
        if (this.idle) clearTimeout(this.idle);
        this.idle = setTimeout(() => {
          this.idle = undefined;
          this.setBannerMode("");
        }, 10000);
      }
    },
  },
  methods: {
    ...actionsBanner({ setBannerMode: "setMode" }),
    closeBanner() {
      this.setBannerMode("");
    },
    setMode(mode: string) {
      this.$store.commit("setMode", mode);
    },
  },
});
