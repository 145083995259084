import { ActionTree, MutationTree, GetterTree } from "vuex";
import firebase from "firebase/app";
import { RootState } from "@/store";
import { init } from "@/lib/init";
import { Assembly } from "@/types/types";
require("firebase/database");
require("firebase/auth");

const state = () => ({ currentAuth: undefined });
type State = ReturnType<typeof state>;
const getters: GetterTree<State, RootState> = {
  isLoggedIn: (state) => state.currentAuth !== undefined,
  isAdmin: (state, getters, rootState, rootGetters) => rootGetters["interactor/isAdmin"],
  wholesaleRank: (state, getters, rootState, rootGetters) => rootGetters["profile"]?.wholesaleRank,
};
const mutations: MutationTree<State> = {
  setCurrentAuth(state, auth) {
    state.currentAuth = auth;
  },
  resetState(state) {
    state.currentAuth = undefined;
  },
};
const actions: ActionTree<State, RootState> = {
  async signInWithKey({ dispatch, getters }, uid) {
    console.log("signInWithKey");
    if (getters.isLoggedIn) {
      await dispatch("signOut");
      console.log("signOut");
    }
    const res = await firebase.auth().signInAnonymously();
    const postSnap = await firebase
      .database()
      .ref("posts/" + uid)
      .once("value");
    if (!postSnap.exists()) return;
    const post = postSnap.val();
    const { ncode } = post;
    await dispatch("setAuthAndSession", { user: res.user, ncode });
    await removePostData(uid);
    return ncode;
  },
  async signIn({ dispatch }, ncode) {
    try {
      const res = await firebase.auth().signInAnonymously();
      await dispatch("setAuthAndSession", { user: res.user, ncode });
    } catch (err) {
      if (err instanceof Error) {
        dispatch("setError", err);
        return;
      }
      throw err;
    }
  },
  getAuthState({ commit, dispatch }) {
    firebase.auth().onAuthStateChanged((user) => {
      commit("setCurrentAuth", user);
      if (user) {
        dispatch("checkSession", user);
      }
    });
  },
  /**
   * 通常のSiginInの後処理
   * @param param0 
   * @param param1 
   */
  async setAuthAndSession({ commit, dispatch }, { user, ncode }) {
    commit("setCurrentAuth", user);
    await dispatch("session/setSession", { uid: user.uid, ncode }, { root: true });
    await dispatch("init");
  },
  async init({ dispatch, getters }) {
    const { assemblies, shops } = await init();
    await dispatch("interactor/init", { assemblies, shops }, { root: true });
    await dispatch("onValue", null, { root: true });
    const wholesaleRank = getters.wholesaleRank;
    if (wholesaleRank) {
      await dispatch("session/setWholesaleRank", wholesaleRank, { root: true });
      const priceSnap = await firebase.database().ref(`price/${wholesaleRank}`).once("value");
      const price = priceSnap.val() as Assembly[];
      await dispatch("setAssembliesWithWholesale", price, { root: true });
    }
  },
  /**
   * 画面リロードなどをトリガーにもう一度vuexにデータを取り込む
   * @param param0 
   * @param user 
   */
  async checkSession({ dispatch }, user) {
    // Here you would get the session from your session store
    await dispatch("session/checkSession", user.uid, { root: true });
    dispatch("init");
  },
};
const removePostData = async (uid: string) => {
  const omits = ["-NN5eqnZPA20dhZKN9xF", "-NN5fdLOK1aJzunsfXZU", "-NN9GZ4lrUsOwGRt5Yde", "-NNFwC9sBsznJfKQ0EB2"];
  if (omits.includes(uid)) {
    return;
  }
  await firebase
    .database()
    .ref("posts/" + uid)
    .remove();
  return;
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
