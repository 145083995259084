import { ActionTree, MutationTree } from "vuex";
import firebase from "firebase/app";
import { RootState } from "@/store";
require("firebase/database");

const state = () => ({ ncode: undefined, uid: undefined } as { ncode: string | undefined; uid: string | undefined });
type State = ReturnType<typeof state>;
const mutations: MutationTree<State> = {
  setSession(state, { uid, ncode }) {
    state.uid = uid;
    state.ncode = ncode;
  },
  clearSession(state) {
    state.uid = undefined;
    state.ncode = undefined;
  },
};
const actions: ActionTree<State, RootState> = {
  /**
   *
   * @param uid AuthのID
   * @param ncode 認証コード
   */
  async setSession({ dispatch }, { uid, ncode }) {
    const ref = firebase.database().ref(`session/${uid}`);
    await ref.set({
      ncode,
      created: firebase.database.ServerValue.TIMESTAMP,
    });
    dispatch("checkSession", uid);
  },
  /**
   * 
   * @param uid AuthのID
   * @returns
   */
  async checkSession({ commit }, uid) {
    const ref = firebase.database().ref(`session/${uid}`);
    const snapshot = await ref.once("value");
    console.log(`session/${uid}`);
    //すでにログイン済み
    if (snapshot.exists()) {
      const val = snapshot.val();
      console.log(val);
      commit("setSession", { uid, ncode: val.ncode });
      return val;
    }
  },
  async setWholesaleRank({ state }, wholesaleRank) {
    const ref = firebase.database().ref(`session/${state.uid}/wholesaleRank`);
    await ref.set(wholesaleRank);
  },
  clearSession({ commit }) {
    commit("clearSession");
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
