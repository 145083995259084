import { ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/store";
const state = () => ({ mode: "" });
type State = ReturnType<typeof state>;
const getters: GetterTree<State, RootState> = {
  show: (state) => {
    return state.mode !== "";
  },
};
const mutations: MutationTree<State> = {
  setMode: (state, mode: string) => {
    state.mode = mode;
  },
};
const actions: ActionTree<State, RootState> = {
  setMode: ({ commit }, mode: string) => {
    commit("setMode", mode);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
