
import Vue from "vue";
import ShoppingOrderTable from "@/components/ShoppingCart/OrderTable.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState: stateCart } = createNamespacedHelpers("cart");
export default Vue.extend({
  name: "ShoppingCart",
  components: {
    ShoppingOrderTable,
  },
  computed: {
    ...stateCart(["items"]),
  },
  methods: {
    setMode(mode: string) {
      this.$store.commit("setMode", mode);
    },
  },
});
