import { render, staticRenderFns } from "./AssemblyTable.vue?vue&type=template&id=43b9f2aa&"
import script from "./AssemblyTable.vue?vue&type=script&lang=ts&"
export * from "./AssemblyTable.vue?vue&type=script&lang=ts&"
import style0 from "./AssemblyTable.vue?vue&type=style&index=0&id=43b9f2aa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports