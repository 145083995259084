import Vue from "vue";
import Vuex from "vuex";
import realtimedb from "./modules/realtimedb";
import interactor from "./modules/interactor";
import assembly from "./modules/assembly";
import auth from "./modules/auth";
import cart from "./modules/cart";
import download from "./modules/download";
import session from "./modules/session";
import banner from "./modules/banner";
import firebase from "firebase/app";
require("firebase/database");
import { homeCode } from "@/config/const";
type homeCodeType = (typeof homeCode)[number];
Vue.use(Vuex);

import { Assembly, MyDataBase, MyDict, Order, Shop } from "@/types/types";
export type RootState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  assemblies: Assembly[];
  assembliesWithWholesale: Assembly[];
  orders: MyDict<Order>;
  shops: Shop[];
  assemblyIdList: string[];
  dateList: string[];
  mode: homeCodeType;
};
export default new Vuex.Store<RootState>({
  state: () => ({
    assemblies: [],
    assembliesWithWholesale: [],
    orders: {},
    shops: [],
    assemblyIdList: [],
    dateList: [],
    mode: "home" as homeCodeType,
  }),
  getters: {
    ncode: (state) => state.session?.ncode,
    uid: (state) => state.session?.uid,
    isAdmin: (state, getters) => ["YMPC1", "YMSJ1", "SUGO1"].includes(getters.ncode),
    filteredAssemblies: (state, getters) => {
      const { assembliesWithWholesale, assemblies } = state;
      if (getters.isAdimin) {
        return assemblies;
      }
      return assembliesWithWholesale;
    },
    profile: (state, getters, rootState) => {
      const ncode = rootState.session.ncode;
      if (!state.shops || !ncode) {
        return undefined;
      }
      return state.shops.find((d: Shop) => d.ncode === ncode);
    },
  },
  mutations: {
    setAssemblies: (state, assemblies) => {
      state.assemblies = assemblies;
    },
    setShops: (state, shops) => {
      state.shops = shops;
    },
    setAssemblyIdList: (state, assemblyIdList) => {
      state.assemblyIdList = assemblyIdList;
    },
    setDateList: (state, dateList) => {
      state.dateList = dateList;
    },
    setOrders: (state, orders) => {
      state.orders = orders;
    },
    setAssembliesWithWholesale: (state, assembliesWithWholesale) => {
      state.assembliesWithWholesale = assembliesWithWholesale;
    },
    setMode: (state, mode) => {
      state.mode = mode;
    },
  },
  actions: {
    //一回しか通らない
    init({ commit }, myDb: MyDataBase) {
      commit("setAssemblies", myDb.assemblies);
      commit("setShops", myDb.shops || []);
    },
    /**
     * 購読するもの
     * AssemblySortとDataListとOrders
     */
    onValue({ commit, getters }) {
      const assemblySortRef = firebase.database().ref("assemblySort");
      assemblySortRef.on("value", (snapshot) => {
        const data = snapshot.val();
        commit("setAssemblyIdList", data || []);
      });
      const dateListRef = firebase.database().ref("dateList");
      dateListRef.on("value", (snapshot) => {
        const data = snapshot.val();
        commit("setDateList", data || []);
      });
      commit("setOrders", {});
      if (getters.isAdmin) {
        const orderRef = firebase.database().ref("order");
        orderRef.on("value", (snapshot) => {
          if (!snapshot.exists()) {
            commit("setOrders", {});
            return;
          }
          const myorders = Object.values(snapshot.val()).reduce(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (acc: MyDict<Order>, cur: any) => ({ ...acc, ...cur }),
            {}
          ) as MyDict<Order>;

          commit("setOrders", myorders);
        });
      } else {
        const orderRef = firebase.database().ref("order/" + getters.ncode);
        orderRef.on("value", (snapshot) => {
          if (!snapshot.exists()) {
            commit("setOrders", {});
            return;
          }
          commit("setOrders", snapshot.val() as MyDict<Order>);
        });
      }
    },
    /**
     * assmeblyにwholesaleを追加する
     * @param param0 
     * @param assembliesWithWholesale 
     */
    setAssembliesWithWholesale({ commit, state }, assembliesWithWholesale: Assembly[]) {
      const myAssembly = state.assemblies
        .filter(({ id }) => assembliesWithWholesale.some((assembly) => assembly.id === id))
        .map((d) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { wholesale } = assembliesWithWholesale.find((assembly) => assembly.id === d.id)!;
          return { ...d, wholesale } as Assembly;
        });
      commit("setAssembliesWithWholesale", myAssembly);
    },
    /**
     * メイン画面のモードを変更して画面表示を変える
     * @param param0
     * @param mode 
     */    
    setMode({ commit, state }, mode: homeCodeType) {
      if (mode === "home" && state.mode === "cart") {
        commit("banner/setMode", "cart");
      }
      commit("setMode", mode);
    },
  },
  modules: {
    assembly,
    auth,
    banner,
    cart,
    download,
    realtimedb,
    interactor,
    session,
  },
});
