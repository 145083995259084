
import Vue from "vue";
import HistoryList from "@/components/OrderHistory/HistoryList.vue";
import dayjs from "dayjs";
import { createNamespacedHelpers } from "vuex";
import { MyDict, Order } from "@/types/types";
const { mapGetters: gettersInteractor } = createNamespacedHelpers("interactor");

export default Vue.extend({
  components: {
    HistoryList,
  },
  computed: {
    ...gettersInteractor({ interactorOrders: "orders" }),
    orders() {
      return Object.entries(this.interactorOrders as MyDict<Order>)
        .map(([id, value]) => ({ ...value, id }))
        .sort((a, b) => (dayjs(a.created).isAfter(dayjs(b.created)) ? -1 : 1));
    },
  },
  methods: {
    setMode(mode: string) {
      this.$store.commit("setMode", mode);
    },
  },
});
