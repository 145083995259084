import { ActionTree, MutationTree } from "vuex";
import dayjs from "dayjs";
import { download, parse, parseByShop, downloadByShop } from "@/assets/excel/XlsxParser";
import { RootState } from "@/store";
import { MyDict, Order } from "@/types/types";

const state = () => ({ type: "1" });
type State = ReturnType<typeof state>;

const mutations: MutationTree<State> = {
  setType: (state, type: string) => {
    state.type = type;
  },
};
const actions: ActionTree<State, RootState> = {
  async downloadByDay({ dispatch, rootGetters }, day) {
    const filterdOrders = Object.entries(rootGetters["interactor/orders"] as MyDict<Order>)
      .map(([id, value]) => ({ ...value, id }))
      .filter((d) => dayjs(d.created).format("YYYY-MM-DD") === day);
    dispatch("perseAndDownload", { filterdOrders, rangeName: day });
  },
  async downloadByBetween({ dispatch, rootGetters }, { from, to }) {
    const fromDay = dayjs(from);
    const toDay = dayjs(to).add(1, "day");
    const filterdOrders = Object.entries(rootGetters["interactor/orders"] as MyDict<Order>)
      .map(([id, value]) => ({ ...value, id }))
      .filter((d) => {
        return dayjs(d.created).isAfter(fromDay) && dayjs(d.created).isBefore(toDay);
      });
    dispatch("perseAndDownload", { filterdOrders, rangeName: `${from}-${to}` });
  },
  perseAndDownload({ state, rootGetters }, { filterdOrders, rangeName }) {
    if (filterdOrders.length === 0) {
      window.alert(`${rangeName}のデータはありません。`);
      return;
    }
    if (state.type === "1") {
      download(
        parse(filterdOrders, rootGetters["shop/shops"], rootGetters["assemblies"]),
        `all_${rangeName}`
      );
    } else if (state.type === "2") {
      const shop = parseByShop(filterdOrders, rootGetters["shop/shops"], rootGetters["assemblies"]);
      downloadByShop(shop.parseJson, shop.shops, `shop_${rangeName}`);
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
