
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const { mapState:stateAssembly, mapGetters: gettersAssembly, mapActions: actionsAssembly } = createNamespacedHelpers("assembly");
export default Vue.extend({
  name: "FilterCategoryWord",
  data: () => ({
    timeout: null as number | null,
    debounceName: null as string | null,
  }),
  computed: {
    ...stateAssembly(["filter"]),
    ...gettersAssembly(["categories"]),
    category: {
      get() {
        return this.filter?.category;
      },
      set(val) {
        this.setFilterCategory(val);
      },
    },
    name: {
      get() {
        return this.debounceName;
      },
      set(val: string | null) {
        this.debounceName = val;
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setFilterName(val);
          console.log("aa");
        }, 500);
      },
    },
  },
  mounted() {
    if (this.filter?.name) {
      this.debounceName = this.filter?.name;
    }
  },
  methods: {
    ...actionsAssembly(["setFilterName", "setFilterCategory"]),
  },
});
