
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "MessageDialog",
  props: {
    message: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  computed: {
    isShow: {
      get() {
        if (this.message) {
          return true;
        } else {
          return false;
        }
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("update:message", undefined);
        }
      },
    },
  },
});
