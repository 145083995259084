import { Assembly, Shop } from "@/types/types";
import firebase from "firebase/app";
require("firebase/database");
export const init = async () => {
  const assembliesSnap = await firebase.database().ref("assembly").once("value");
  const assemblies = assembliesSnap.val() as Assembly[];
  const shopSnap = await firebase.database().ref("shop").once("value"); 
  const shops = shopSnap.val() as Shop[];
  console.log(assemblies, shops);
  return { assemblies, shops };
};