
import { writePrice } from "@/lib/price";
import { assemblyKeyToName, orderDetailKeyToName } from "@/config/const";
import MessageDialog from "@/components/Global/MessageDialog.vue";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { Assembly, Shop } from "@/types/types";
type OrderItem = Assembly & {
  quantity: number;
  backorder: string;
  note?: string;
};
const { mapGetters: gettersCart, mapActions: actionsCart } = createNamespacedHelpers("cart");
export default Vue.extend({
  name: "OrderTable",
  data: () => ({
    isLoading: false,
    shopcode: "",
    shop: undefined as Shop | undefined,
    errorMessageForShopCode: "",
    messageForDialog: "",
  }),
  components: {
    MessageDialog,
  },
  computed: {
    assemblyKeyToName() {
      return assemblyKeyToName;
    },
    orderDetailKeyToName() {
      return orderDetailKeyToName;
    },
    ...gettersCart(["itemList", "totalAmount"]),
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    includeBo() {
      return this.itemList.some((item: OrderItem) => item.backorder === "×");
    },
    disabledPurchased() {
      if (this.isAdmin && this.shopcode !== "" && !this.shop) {
        return true;
      }
      return this.isLoading;
    },
  },
  watch: {
    shopcode: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.errorMessageForShopCode = "";
          return;
        }
        this.shop = this.$store.getters.findByShopcode[newVal];
        if (this.shop || newVal === "") {
          this.errorMessageForShopCode = "";
        } else {
          this.errorMessageForShopCode = "販売店コードがありません";
        }
      },
    },
  },
  methods: {
    ...actionsCart(["remove", "purchase"]),
    isBo(backorder?: string) {
      return backorder === "×";
    },
    writePrice: writePrice,
    async purchased() {
      this.isLoading = true;
      this.$store.commit("setMode", "home");
      this.isLoading = false;
    },
  },
});
