
import Vue, { PropType } from "vue";
import { writePrice } from "@/lib/price";
import { convertUtoStringShort } from "@/lib/date";
import HistoryDialog from "./HistoryDialog.vue";
import { createNamespacedHelpers } from "vuex";
import { Order } from "@/types/types";
const { mapGetters: gettersInteractor } = createNamespacedHelpers("interactor");

export default Vue.extend({
  name: "HistoryList",
  components: {
    HistoryDialog,
  },
  props: {
    orders: {
      type: Array as PropType<Order[] | undefined>,
      default: undefined,
    },
  },
  data: () => ({
    currentOrder: undefined as Order | undefined,
    idle: undefined as number | undefined,
    debouncedSearch: undefined as string | undefined,
  }),
  computed: {
    ...gettersInteractor(["isAdmin"]),
    filteredOrder() {
      if (!this.debouncedSearch) {
        return this.orders;
      }
      return this.orders!.filter(
        ({ id, ncode, details }) =>
          id.indexOf(this.debouncedSearch!) > -1 ||
          ncode.indexOf(this.debouncedSearch!) > -1 ||
          details.find((d) => d.name.indexOf(this.debouncedSearch!) > -1 || d.id.indexOf(this.debouncedSearch!) > -1)
      );
    },
  },
  methods: {
    writePrice,
    convertUtoStringShort,
  },
});
