import { ActionTree, MutationTree, GetterTree } from "vuex";
import dayjs from "dayjs";
import firebase from "firebase/app";
import { RootState } from "@/store";
require("firebase/database");

const state = () => ({ offset: 0 });
type State = ReturnType<typeof state>;
const getters: GetterTree<State, RootState> = {
  isBusinessHours: ({ offset }) => dayjs(new Date().getTime() + offset).hour() > 6,
};
const mutations: MutationTree<State> = {
  setOffset(state, offset) {
    state.offset = offset;
  },
};
const actions: ActionTree<State, RootState> = {
  getServerTimeOffset({ commit }) {
    firebase
      .database()
      .ref(".info/serverTimeOffset")
      .on("value", (snapshot) => {
        commit("setOffset", snapshot.val() as number);
      });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
