export const categoriesSort = ["KT", "MZ", "MX", "タイヤ", "オイル", "その他"];
export const backorderCode = ["〇", "△", "×"] as const;
export const homeCode = ["home", "cart", "history"] as const;
export const exportCode = {
  "1": "一覧",
  "2": "販売店毎",
} as const;

export const assemblyKeyToName = {
  id: "部品番号",
  name: "部品名",
  category: "カテゴリ",
  locationCode: "ロケーション",
  ordering_unit: "発注単位",
  wholesale: "仕切価格",
  price: "メーカー希望小売価格(税別)",
  backorder: "在庫",
  note: "備考",
  moq: "最低注文数",
} as const;

export const orderKeyToName = {
  id: "注文番号",
  ncode: "認証コード",
  details: "注文明細",
  //オープン価格の時はゼロが入る
  totalAmount: "合計金額(税別)",
  created: "注文日",
  modified: "変更日",
} as const;

export const orderDetailKeyToName = {
  id: "注文番号",
  name: "商品名",
  quantity: "注文数",
  bo: "BO",
  //オープン金額があるため
  price: "価格",
  note: "備考",
} as const;

export const shopKeyToName = {
  shopcode: "販売店コード",
  ncode: "認証コード",
  address: "住所",
  address_pref: "住所(県）",
  name: "店名",
  tel: "電話番号",
  zip: "郵便番号",
} as const;

export const shopRankCodeToKey = {
  "1": "general",
  "2": "royal",
  "3": "special",
  "9": "sugo",
} as const;

export const shopRankCodeToName: { [key: string]: string } = {
  "1": "一般",
  "2": "ロイヤル",
  "3": "特約",
  "9": "菅生",
};
