
import Vue, { PropType } from "vue";
import { writePrice } from "@/lib/price";
import { createNamespacedHelpers } from "vuex";
import { assemblyKeyToName, orderDetailKeyToName } from "@/config/const";
import MessageDialog from "@/components/Global/MessageDialog.vue";
import { Order, Order_detail } from "@/types/types";
const { mapActions: actionsInteractor } = createNamespacedHelpers("interactor");
export default Vue.extend({
  name: "HistoryTable",
  components: {
    MessageDialog,
  },
  props: {
    order: {
      type: Object as PropType<Order | undefined>,
      default: undefined,
    },
    isAdmin: Boolean,
  },
  data() {
    return {
      messageForDialog: "",
    };
  },
  computed: {
    assemblyKeyToName() {
      return assemblyKeyToName;
    },
    orderDetailKeyToName() {
      return orderDetailKeyToName;
    },
    includeBo() {
      return this.order?.details.some((detail: Order_detail) => detail.bo && detail.bo > 0);
    },
    detail() {
      if (this.order) {
        return this.order.details;
      }
      return [];
    },
  },
  methods: {
    ...actionsInteractor({ cancelDetail: "cancelOrderDetail" }),
    writePrice: writePrice,
    cancelOrderDetail(idx: number) {
      if (window.confirm("取消するともどりませんがよろしいでしょうか？")) {
        this.cancelDetail(this.order!.id, idx);
        this.$emit("cancel");
      }
    },
  },
});
