
import Vue, { PropType } from "vue";
import { writePrice } from "@/lib/price";
import { convertUtoString, isToday } from "@/lib/date";
import { createNamespacedHelpers } from "vuex";
import { Order } from "@/types/types";
import HistoryTable from "./HistoryTable.vue";
const { mapActions: actionsInteractor } = createNamespacedHelpers("interactor");
export default Vue.extend({
  name: "HistoryDialog",
  components: {
    HistoryTable,
  },
  props: {
    order: {
      type: Object as PropType<Order | undefined>,
      default: undefined,
    },
    isAdmin: Boolean,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    orderDate() {
      if (!this.order?.created) {
        return "";
      }
      return convertUtoString(this.order.created);
    },
    showPrice() {
      return this.order!.totalAmount > 0;
    },
    orderPrice() {
      if (!this.order?.totalAmount) {
        return "";
      }
      return writePrice(this.order.totalAmount);
    },
    isShow: {
      get() {
        return !!this.order;
      },
      set(val: boolean) {
        if (!val) {
          this.$emit("update:order", undefined);
        }
      },
    },
    canCancel() {
      if (this.isAdmin) {
        return true;
      }
      return isToday(this.order?.created);
    },
    cautionMsg() {
      return this.isAdmin ? "発送状況をご確認の上取消を実行してください。" : "発注日当日の24時までは取消ができます。";
    },
    btnMsg() {
      return this.isAdmin ? "取消" : "発注取消";
    },
  },
  methods: {
    ...actionsInteractor(["cancelOrder"]),
    convertUtoString: convertUtoString,
    writePrice: writePrice,
    async orderCancel() {
      this.isLoading = true;
      if (confirm("取消してよろしいでしょうか?")) {
        await this.cancelOrder(this.order!.id);
        this.$emit("update:order", undefined);
      }
      this.isLoading = false;
    },
    handleHistoryTableCancel() {
      this.$emit("update:order", undefined);
    },
  },
  watch: {
    order: {
      handler(newOrder) {
        this.isShow = !!newOrder;
      },
      immediate: true,
    },
  },
});
