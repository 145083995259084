import { categoriesSort } from "@/config/const";

export const sortCategories = (categories: string[]) => {
  return Array.from(new Set(categories)).sort((a, b) =>
    getSortIndex(a) > getSortIndex(b) ? 1 : -1
  );
};

const getSortIndex = (category: string) =>
  categoriesSort.indexOf(category) === -1
    ? 99999
    : categoriesSort.indexOf(category);
