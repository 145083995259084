import dayjs from "dayjs";

export const convertUtoString = (arg: number | string) => {
  return dayjs(arg).format("YYYY/MM/DD HH:mm");
};
export const convertUtoStringShort = (arg: number | string) => {
  return dayjs(arg).format("M/D H:mm");
};

export const convertUtoStringFormat = (
  arg: number | string,
  format: string
) => {
  return dayjs(arg).format(format);
};
export const isToday = (arg: number | string | undefined) => {
  if (!arg) {
    return false;
  }
  return dayjs(arg).isSame(dayjs(), "day");
};
