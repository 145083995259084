import { ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/store";
import { Assembly } from "@/types/types";
type item = {
  assembly_id: string;
  quantity: number;
  note?: string;
};
const state = () => ({ items: JSON.parse(localStorage.getItem("my-shoppingcart") || "[]") as item[] });
type State = ReturnType<typeof state>;
const getters: GetterTree<State, RootState> = {
  totalAmount: (state, getters, rootState, rootGetters) => {
    const assembiles = rootGetters["interactor/assemblies"] as Assembly[];
    if (assembiles.length === 0) {
      return 0;
    }
    if (state.items.find(({ assembly_id }) => !assembiles.find(({ id }) => id === assembly_id)!.price)) {
      return 0;
    }
    return state.items.reduce(
      (acc, { assembly_id, quantity }) => acc + assembiles.find((d) => d.id === assembly_id)!.price! * quantity,
      0
    );
  },
  itemList: (state, getters, rootState, rootGetters) => {
    const assembiles = rootGetters["interactor/assemblies"] as Assembly[];
    return state.items.map((d) => ({
      ...d,
      ...assembiles.find(({ id }) => id === d.assembly_id),
    }));
  },
};
const mutations: MutationTree<State> = {
  add(state, payload: item) {
    console.log(payload);
    state.items.push(payload);
    localStorage.setItem("my-shoppingcart", JSON.stringify(state.items));
  },
  remove(state, idx) {
    if (state.items.length > idx) {
      state.items.splice(idx, 1);
    }
    localStorage.setItem("my-shoppingcart", JSON.stringify(state.items));
  },
  clear(state) {
    state.items = [];
    localStorage.setItem("my-shoppingcart", "[]");
  },
};
const actions: ActionTree<State, RootState> = {
  add({ commit, dispatch }, { assembly_id, quantity, note }: item) {
    commit("add", { assembly_id, quantity, ...(note && { note }) });
    dispatch("banner/setMode", "cart", { root: true });
  },
  remove({ commit }, idx) {
    commit("remove", idx);
  },
  async purchase({ state, getters, dispatch, commit }, ncode) {
    if (state.items.length > 0) {
      await dispatch(
        "interactor/purchase",
        { contents: state.items, totalAmount: getters.totalAmount, ncode: ncode },
        { root: true }
      );
      commit("clear");
      dispatch("banner/setMode", "history", { root: true });
    }
  },
  clear({ commit }) {
    commit("clear");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
