import { ActionTree, MutationTree, GetterTree } from "vuex";
import firebase from "firebase/app";
import { RootState } from "@/store";
import _ from "lodash";
import { Assembly, MyDataBase, Order_detail, Shop } from "@/types/types";
require("firebase/database");

const state = () => ({ myDb: {} as MyDataBase });
type State = ReturnType<typeof state>;
const getters: GetterTree<State, RootState> = {
  assemblies: (state, getters, rootState, rootGetters) => {
    return rootGetters.filteredAssemblies as Assembly[];    
  },
  assemblyIdList: (state, getters, rootState) => {
    return rootState.assemblyIdList;
  },
  dateList: (state, getters, rootState) => {
    return rootState.dateList;
  },
  orders: (state, getters, rootState) => {
    return rootState.orders;
  },
  shops: (state, getters, rootState) => {
    return rootState.shops;
  },
  ncode: (state, getters, rootState, rootGetters) => {
    return rootGetters.ncode;
  },
  isAdmin: (state, getters, rootState, rootGetters) => {
    return rootGetters.isAdmin;
  },
  sortedAssemblies: (state, getters, rootState, rootGetters) => {
    //このままだとそのものをコピーしてしまう。
    const cloneFilteredAssemblies: Assembly[] = _.cloneDeep(rootGetters.filteredAssemblies);
    return cloneFilteredAssemblies.sort((a, b) => {
      const indexA = getSortIndex(getters.assemblyIdList, a.id!);
      const indexB = getSortIndex(getters.assemblyIdList, b.id!);
      return indexA - indexB;
    });
  },
  findByShopcode: (state, getters) => (shopcode: string) => {
    if (shopcode.length < 5) {
      return undefined;
    }
    return getters.shops.find((d: Shop) => d.shopcode === shopcode);
  },
  findByNcode: (state, getters) => (ncode: string) => {
    if (ncode.length < 5) {
      return undefined;
    }
    return getters.shops.find((d: Shop) => d.ncode === ncode);
  },
};
const mutations: MutationTree<State> = {
  setMyDb: (state, myDb: MyDataBase) => {
    state.myDb = myDb;
  },
};
const actions: ActionTree<State, RootState> = {
  async init(context, myDb: MyDataBase) {
    context.commit("setMyDb", myDb);
    await context.dispatch("init", myDb, { root: true });
  },
  async purchase(
    context,
    payload: {
      contents: { assembly_id: string; quantity: number; note?: string }[];
      totalAmount: number;
      ncode?: string;
    }
  ) {
    const contents = payload.contents;
    const orderDetails = contents.map(({ assembly_id, quantity, note }) => {
      const { id, name, price, backorder } = context.getters.assemblies.find(
        (that: Assembly) => that.id === assembly_id
      )!;
      const isBo = backorder === "×";
      //nullの項目があるとFirebaseがエラー出す。
      return {
        id,
        name,
        ...(price && { price }),
        quantity: isBo ? 0 : quantity,
        bo: isBo ? quantity : 0,
        ...(note && { note }),
      };
    });
    const now = Date.now().toString();
    const ncode = payload.ncode || context.getters.ncode;
    const order = {
      id: now,
      ncode,
      details: orderDetails,
      totalAmount: payload.totalAmount,
      created: firebase.database.ServerValue.TIMESTAMP,
      modified: 0,
    };
    // まず新しい参照を生成します
    const newRef = firebase.database().ref(`order/${ncode}`).push();

    // それからキー（ID）を取得します
    const key = newRef.key!;

    // オーダーオブジェクトのidフィールドにキーを設定します
    order.id = key;

    // データをデータベースに保存します
    newRef
      .set(order)
      .then(function () {
        console.log("Order saved successfully.");
      })
      .catch(function (error) {
        console.log("Data could not be saved." + error);
      });
  },
  async cancelOrder(context, order_id) {
    const { ncode } = context.getters.orders[order_id];
    if (!context.getters.isAdmin) {
      if (ncode !== context.getters.ncode) {
        console.error("認証コードが違います。");
        return;
      }
    }
    const order = context.getters.orders[order_id];
    if (order) {
      await firebase.database().ref(`deleted_order`).push(order);
    }
    await firebase.database().ref(`order/${ncode}/${order_id}`).remove();
    return order_id;
  },
  async cancelOrderDetail(context, { order_id, order_idx }) {
    const { ncode } = context.getters.orders[order_id];
    await context.dispatch("cancelOrderDetail", { ncode, order_id, order_idx }, { root: true });
    const { details } = context.getters.orders[order_id];
    const detail = details.splice(order_idx, 1);
    if (detail) {
      await firebase.database().ref(`order/${ncode}/${order_id}/cancel_detail`).push(detail);
    }
    await firebase.database().ref(`order/${ncode}/${order_id}/details`).set(details);
    let totalAmount = 0;
    if (!details.find((d: Order_detail) => !d.price)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      totalAmount = details.reduce((acc: number, current: Order_detail) => acc + current.price! * current.quantity, 0);
    }
    await firebase.database().ref(`order/${ncode}/${order_id}/totalAmount`).set(totalAmount);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

const getSortIndex = (sort: string[], val: string) => (sort.indexOf(val) === -1 ? 99999 : sort.indexOf(val));
