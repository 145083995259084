
import { writePrice } from "@/lib/price";
import { assemblyKeyToName } from "@/config/const";
import { orderDetailKeyToName } from "@/config/const";
import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";
import { Assembly } from "@/types/types";
const { mapActions: actionsCart } = createNamespacedHelpers("cart");
export default Vue.extend({
  name: "AssemblyOrderDialog",
  data: () => ({
    note: "",
    quantity: 1,
    isLoading: false,
    isShow: false,
  }),
  props: {
    assembly: {
      type: Object as PropType<Assembly | undefined>,
    },
  },
  computed: {
    assemblyKeyToName() {
      return assemblyKeyToName;
    },
    orderDetailKeyToName() {
      return orderDetailKeyToName;
    },
    disabledAddCart() {
      if (
        this.isLoading ||
        this.quantity < 1 ||
        this.quantity > 99 ||
        !this.assembly?.backorder
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isShow(val) {
      if (val) {
        this.quantity = 1;
        this.note = "";
      }
    },
    assembly(val) {
      this.isShow = !!val;
    },
  },
  created() {
    this.isShow = !!this.assembly;
  },
  methods: {
    writePrice: writePrice,
    ...actionsCart(["add"]),
    addCart() {
      this.isLoading = true;
      const item = {
        assembly_id: this.assembly!.id,
        quantity: Number(this.quantity),
        note: this.note === "" ? undefined : this.note,
      };
      this.add(item);
      this.isLoading = false;
      this.isShow = false;
      this.$emit("update:assembly", undefined);
    },
  },
});
