import { ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/store";
import { sortCategories } from "@/lib/sortCategories";
import { Assembly } from "@/types/types";
import unorm from "unorm";
type myFilter = {
  category: string | undefined;
  name: string | undefined;
};
// ie11でたぶん動かない
// const normalizeText = (text: string | undefined) => (text ? text.normalize("NFKC").toLowerCase() : "");
const normalizeText = (text: string | undefined) => (text ? unorm.nfkc(text).toLowerCase() : "");

const isCategoryMatched = (category: string | undefined, assembly: Assembly) =>
  !category || assembly.category === category;

const isNameMatched = (name: string | undefined, assembly: Assembly) => {
  const normalizedName = normalizeText(name);
  const normalizedAssemblyId = normalizeText(assembly.id);
  //文字列の部分に対応
  return !normalizedName || normalizedAssemblyId.includes(normalizedName);
};

const filterAssemblies = (assemblies: Assembly[], filter: { category: string | undefined; name: string | undefined }) =>
  assemblies.filter((assembly) => isCategoryMatched(filter.category, assembly) && isNameMatched(filter.name, assembly));

const state = () => ({ filter: { name: undefined, category: undefined } as myFilter });
type State = ReturnType<typeof state>;
const getters: GetterTree<State, RootState> = {
  categories: (state, getters, rootState, rootGetters) => {
    const assemblies = rootGetters["interactor/assemblies"];
    // console.log(assemblies);
    return sortCategories(assemblies.map((d: Assembly) => d.category));
  },
  /**
   * - カテゴリが設定でカテゴリ同じ
   * - 名前が設定一部名前あり
   * */
  filteredAssemblies: (state, getters, rootState, rootGetters) => {
    const { category, name } = state.filter;
    const sortedAssemblies = rootGetters["interactor/sortedAssemblies"];
    const assemblies = rootGetters["interactor/assemblies"];

    const targetAssemblies = category ? assemblies : sortedAssemblies;
    // return targetAssemblies;
    return filterAssemblies(targetAssemblies, { category, name });
  },
};
const mutations: MutationTree<State> = {
  setFilterCategory: (state, category) => {
    state.filter.category = category;
  },
  setFilterName: (state, name) => {
    state.filter.name = name;
  },
};
const actions: ActionTree<State, RootState> = {
  async setFilterCategory({ commit }, category: string | undefined) {
    commit("setFilterCategory", category);
  },
  async setFilterName({ commit }, name: string | undefined) {
    commit("setFilterName", name);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
