
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: gettersAssembly, mapActions: actionsAssembly } = createNamespacedHelpers("assembly");
const { mapState: stateCart } = createNamespacedHelpers("cart");
const { mapGetters : gettersBanner } = createNamespacedHelpers("banner");
export default Vue.extend({
  name: "AppBar",
  data: () => ({}),
  computed: {
    ...gettersAssembly(["categories"]),
    ...stateCart(["items"]),
    ...gettersBanner({"showBanner": "show"}),
    mode() {
      return this.$store.state.mode;
    },
  },
  methods: {
    setMode(mode: string) {
      this.$store.commit("setMode", mode);
    },
    ...actionsAssembly(["setFilterCategory"]),
  },
});
