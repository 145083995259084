import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import { firebaseConfig } from "./config/firebase";
import "./plugins/polyfills";

Vue.config.productionTip = false;
firebase.initializeApp(firebaseConfig);
router.beforeResolve((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.isPublic);
  if (to.meta?.title) {
    document.title = to.meta.title + " | カート営業部品受発注システム";
  } else {
    document.title = "カート営業部品受発注システム";
  }
  if (isPublic) {
    next();
    return;
  }
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      next();
      return;
    }
    //ログインしてない
    next({ path: "/message", query: { undefinedShop: "true" } });
  });
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    this.$store.dispatch("auth/getAuthState");
  },
}).$mount("#app");
