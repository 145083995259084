
import Vue from "vue";
import { assemblyKeyToName } from "@/config/const";
import AssemblyDialog from "@/components/Home/AssemblyOrderDialog.vue";
import { createNamespacedHelpers } from "vuex";
import { writePrice } from "@/lib/price";
import { Assembly } from "@/types/types";
const { mapGetters: gettersAssembly } = createNamespacedHelpers("assembly");
const { mapGetters: gettersInteractor } = createNamespacedHelpers("interactor");
export default Vue.extend({
  name: "AssemblyTable",
  data: () => ({
    timeout: null as number | null,
    selectedAssembly: undefined as undefined | Assembly,
  }),
  components: { AssemblyDialog },
  computed: {
    assemblyKeyToName() {
      return assemblyKeyToName;
    },
    ...gettersAssembly(["filteredAssemblies"]),
    ...gettersInteractor(["isAdmin"]),
  },
  methods: {
    writePrice(price: number) {
      return writePrice(price);
    },
    handleClickTd(assembly: Assembly) {
      this.selectedAssembly = assembly;
    },
  },
});
