import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/admin",
  //   name: "admin",
  //   component: () => import("../views/AdminView.vue"),
  // },
  {
    path: "/login",
    name: "login",
    meta: { isPublic: true },
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/message",
    name: "message",
    meta: { isPublic: true },
    component: () => import("../views/MessageView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
